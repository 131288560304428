import type { createAccordion } from '@/js/utils/accordion';

import { defineModule } from '@/js/utils/helpers';

const accordions: ReturnType<typeof createAccordion>[] = [];

const getElements = () => ({
  accordionElements: document.querySelectorAll<HTMLElement>(
    '.content-block.content-block--media-accordion-tabs .accordion',
  ),
});

export default defineModule(
  async () => {
    const { accordionElements } = getElements();
    if (!accordionElements) return;

    const { createAccordion } = await import('@/js/utils/accordion');
    if (!accordionElements.length) return;

    accordionElements.forEach((accordionElement) => {
      const accordion = createAccordion(accordionElement, {
        onToggle: (open) => {
          if (!open) return;

          const contentBlock = accordionElement.closest('.content-block');

          accordions.forEach((otherAccordion) => {
            if (
              (contentBlock &&
                otherAccordion.el.closest('.content-block') !== contentBlock) ||
              accordionElement === otherAccordion.el
            )
              return;

            otherAccordion.close();
          });
        },
      });

      accordions.push(accordion);
    });
  },
  () => {
    while (accordions.length) {
      accordions.pop()?.destroy();
    }
  },
);
