import { defineModule } from '@/js/utils/helpers';

const getElements = () => ({
  tabElements: document.querySelectorAll<HTMLElement>(
    '.content-block [role=tab]',
  ),
});

const handleTabClick = (e: Event) => {
  const currentTab = e.target as HTMLElement;

  const contentBlock = currentTab.closest('.content-block');
  if (!contentBlock) return;

  const [tabTriggers, tabContainer] = [
    contentBlock.querySelectorAll<HTMLElement>('[role=tab]'),
    contentBlock.querySelector<HTMLElement>('.tab__container'),
  ];
  if (!tabTriggers.length || !tabContainer) return;

  const firstTab = currentTab === tabTriggers.item(0);

  tabContainer.classList.toggle('-translate-x-1/2', !firstTab);

  tabTriggers.forEach((item) => {
    const isCurrent = item === currentTab;
    item.setAttribute('aria-selected', String(isCurrent));
    item.setAttribute('tabindex', isCurrent ? '-1' : '0');
  });
};

export default defineModule(
  async () => {
    const { tabElements } = getElements();
    if (!tabElements) return;

    tabElements.forEach((tab) =>
      tab.addEventListener('click', handleTabClick, { passive: true }),
    );
  },
  () => {
    const { tabElements } = getElements();
    tabElements.forEach((tab) =>
      tab.removeEventListener('click', handleTabClick),
    );
  },
);
